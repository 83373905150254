<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="heading">Report Details</span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center pa-2>
                        <v-flex xs12 sm12 lg12>
                          <v-layout wrap justify-start>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.reportId &&
                                data.reportId.description
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Description</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.description }}
                                  <span v-if="!data.reportId.description"
                                    >-</span
                                  >
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.reportId &&
                                data.reportId.snake.name
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Snake</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.snake.name }}
                                  <span v-if="!data.reportId.snake.name"
                                    >-</span
                                  >
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data && data.reportId && data.reportId.createdAt
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Created At</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ formatDate(data.reportId.createdAt) }}
                                  <span v-if="!data.reportId.createdAt">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data && data.reportId && data.reportId.situation
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Situation</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.situation }}
                                  <span v-if="!data.reportId.situation">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.division &&
                                data.division.length > 0
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Division Name</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.division[0].divisionName }}
                                  <span v-if="!data.division[0].divisionName"
                                    >-</span
                                  >
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="data && data.district"
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">District</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.district }}
                                  <span v-if="!data.district">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start pt-10>
                            <v-flex
                              xs12
                              v-if="data && data.reportId && data.reportId.user"
                            >
                              <span class="subheading">Reported By </span>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.reportId &&
                                data.reportId.user &&
                                data.reportId.user.name
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Name</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.user.name }}
                                  <span v-if="!data.reportId.user.name">-</span>
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.reportId &&
                                data.reportId.user &&
                                data.reportId.user.phone
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Phone</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.user.phone }}
                                  <span v-if="!data.reportId.user.phone"
                                    >-</span
                                  >
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex
                              xs12
                              sm6
                              lg4
                              md6
                              pt-2
                              v-if="
                                data &&
                                data.reportId &&
                                data.reportId.user &&
                                data.reportId.user.email
                              "
                            >
                              <v-flex xs12 text-left>
                                <span class="itemKey">Email</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.reportId.user.email }}
                                  <span v-if="!data.reportId.user.email"
                                    >-</span
                                  >
                                </span>
                              </v-flex>
                            </v-flex>
                          </v-layout>
                          <v-layout
                            wrap
                            justify-start
                            pt-10
                            v-if="reportedimages.length > 0"
                          >
                            <v-flex xs12>
                              <span class="subheading">Reported Images</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex
                                  xs12
                                  v-for="(item, index) in reportedimages"
                                  :key="index"
                                  lg4
                                  sm4
                                  md4
                                  pa-1
                                  v-viewer="{ movable: false }"
                                >
                                  <v-img
                                    contain
                                    height="200px"
                                    width="100%"
                                    :src="mediaURL + item"
                                    fill-height
                                    class="clickable"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start pt-10>
                            <v-flex xs12>
                              <span class="subheading">Location</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <div id="map"></div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 pt-lg-5 pl-2>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm4 md4 lg4>
                    <v-btn
                      depressed
                      color="warning"
                      @click="approveDialog = true"
                    >
                      <span>Close Operation</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-sm-2 pl-md-2>
                    <v-btn depressed color="error" @click="rejectDialog = true">
                      <span>Delete Operation</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="approveDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Close Operation</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs12 class="subheading">
                  <span>Remarks</span>
                  <v-text-field outlined dense v-model="closeremarks">
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="approveDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="closeoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Close</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text
          >Are you sure you want to delete the operation?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
<script>
var marker;
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      data: [],
      reportedimages: [],
      map: "",
      mapData: {},
      location: [],
      approveDialog: false,
      closeremarks: "",
      rejectDialog: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  //   mounted() {
  //   this.$nextTick(() => {
  //     this.initMap();
  //   });
  // },
  methods: {
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");

      // Set default map options
      var mapOptions = {
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };

      // Create a new map
      vm.map = new google.maps.Map(mapCanvas, mapOptions);

      // Check if location data is available
      if (vm.location && vm.location.length === 2) {
        var lat = vm.location[1]; // Latitude
        var lon = vm.location[0]; // Longitude

        mapOptions.center = new google.maps.LatLng(lat, lon);
        vm.map.setOptions(mapOptions);

        // Create a marker at the location
        var marker = new google.maps.Marker({
          map: vm.map,
          position: new google.maps.LatLng(lat, lon),
        });

        // Create an InfoWindow
        var infowindow = new google.maps.InfoWindow({
          content: `
    <div style="width: 200px; height: 50px;">
      <h3 style="margin: 0;">Reported Location</h3>
    </div>
  `,
        });

        // Add a click event listener to the marker to open the InfoWindow
        marker.addListener("click", function () {
          infowindow.open(vm.map, marker);
        });
      } else {
        // If no location data, set a default map center (you can adjust this as needed)
        mapOptions.center = new google.maps.LatLng(11.5623, 76.5345);
        vm.map.setOptions(mapOptions);
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.data = response.data.data;
              this.reportedimages = response.data.reportedimages;
              this.location = this.data.reportId.location;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    closeoperation() {
      if (!this.closeremarks) {
        this.msg = "Please enter remarks.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/closereport",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          remarks: this.closeremarks,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Report Closed Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.approveDialog = false;
              this.$router.push({ path: "/officers/pendingoperations" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Operation Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog = false;
              this.$router.push({ path: "/officers/pendingoperations" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
      <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
#map {
  height: 300px !important;
  width: 100%;
}
</style>